<template>
<sub-page
    v-model="$store.state.settings.pageData"
    :header-action="openAddExpense"
    header-action-text="Додати видаток"
>
  <template #default>

    <ws-data-table
        :headers="headers"
        :items="items"
        class="mt-5"
        :row-action="openEditExpense"
    >

      <template #item.date="{item}">
        <h5 class="text-no-wrap">{{ PARSE_DATE(item.date , false , true) }}</h5>
      </template>

      <template #item.name="{item}">
        <h5 class="wsACCENT">{{ item.name }}</h5>
      </template>


    </ws-data-table>
  </template>

  <template #dialog>
    <ws-dialog
        v-model="displayDialog"
        :title="newItem ? 'Cтворити видаток' : 'Редагувати видаток'"
        @save="addExpense"
        :show-delete="!newItem"
        @delete="deleteExpense"
    >
      <ws-text-field
          v-model="entityData.name"
          class="mt-5"
          label="Назва"
          placeholder="Введіть назву видатку"
          :error="nameError"
          @input="nameError = false"
      />

      <ws-text-field
          v-model="entityData.price"
          class="mt-5"
          label="Вартість"
          placeholder="Введіть вартість"
          number
          :error="priceError"
          @input="priceError = false"
      />

      <ws-select
          v-model="entityData.expense_type_id"
          :items="expensesTypesSelect"
          label="Тип видатку"
          placeholder="Оберіть тип видатку"
          class="mt-5"
          :error="typeError"
          @input="typeError = false"
      />

      <ws-select
          v-if="expenseTypeName === 'Зарплата'"
          v-model="entityData.user_id"
          :items="managersSelect"
          label="Співробітник"
          placeholder="Оберіть співробітника"
      />

      <ws-date-picker
          label="Дата"
          placeholder="Сьогоднішня дата"
          v-model="entityData.date"
          time-stamp
      />


    </ws-dialog>
  </template>

</sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "accounitngPayments",
  data() {
    return {
      items : [],
      entityData : {},
      displayDialog : false,
      managersSelect : [],
      expensesTypesSelect : [],
      newItem : true,
      priceError : false,
      nameError : false,
      typeError : false
    }
  },
  computed : {
    expenseTypeName() {
      if ( !this.entityData.expense_type_id ) {
        return null
      }
      let expenseType = this.expensesTypesSelect.find(el => el.value === this.entityData.expense_type_id)
      if ( !expenseType ) {
        return null
      }
      return expenseType.text
    },
    headers() {
      return [
        { text : 'Дата'    , value : 'date' , width : 10 },
        { text : 'Назва'   , value : 'name' },
        { text : 'Cпівробітник'     , value : 'user_name' , width : 10 },
        { text : 'Тип'     , value : 'expense_type_name' , width : 10 },
        { text : 'Cума'    , value : 'price' , width : 10 },
      ]
    }
  },
  methods : {
    ...mapActions('adminPayments', [
      'GET_EXPENSES',
      'ADD_EXPENSE',
      'DELETE_EXPENSE'
    ]),

    openEditExpense(item) {
      this.entityData = this.COPY(item)
      this.newItem = false
      this.displayDialog = true
    },
    async deleteExpense() {
      let result = await this.DELETE_EXPENSE(this.entityData.uuid)
      if ( !result ) {
        return this.ERROR('Не має прав на видалення запису')
      }
      let index = this.items.findIndex( el => el.uuid === this.entityData.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.displayDialog = false
    },
    async addExpense() {
      if ( !this.entityData.price ) {
        this.priceError = true
        return this.notify('Введіть суму видатку' , 'warning')
      }
      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify('Введіть назву видатку' , 'warning')
      }
      if ( !this.entityData.expense_type_id ) {
        this.typeError = true
        return this.notify('Введіть тип видатку' , 'warning')
      }
      let result = await this.ADD_EXPENSE(this.entityData)
      if ( !result ) {
        return this.ERROR()
      }

      if ( this.newItem ) {
        this.items.unshift(result)
      } else {
        let index = this.items.findIndex( el => el.uuid === result.uuid )
        if ( index === -1 ) {
          return
        }
        this.items[index] = this.COPY(result)
        this.items = this.COPY(this.items)
      }

      this.displayDialog = false


    },

    openAddExpense() {
      this.newItem = true
      this.entityData = {}
      this.displayDialog = true
    },

    async initPage() {
      let result = await this.GET_EXPENSES()
      if ( !result ) {
        return this.ERROR()
      }
      result.items.reverse()
      this.items = result.items
      this.managersSelect = result.managers_select
      this.expensesTypesSelect = result.expenses_types_select
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>