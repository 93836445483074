<template>
<dash-page-new
    title="Видатки"
    icon="mdi-abacus"
>
  <template #header_action>

    <v-btn v-if="$store.state.settings.pageData.headerAction"
           @click="$store.state.settings.pageData.headerAction()"
           :color="wsATTENTION"
           class="noCaps"
           dark
    >
      {{ $store.state.settings.pageData.headerActionText }}
    </v-btn>
  </template>

  <accounitng-expenses />
</dash-page-new>
</template>

<script>
import accounitngExpenses from "@/components/pages/adminDashboard/accounting/accounitngExpenses";
export default {
  name: "DashAdminExpenses",
  components : {
    accounitngExpenses
  }
}
</script>

<style scoped>

</style>